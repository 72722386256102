import React, { Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import i18n from '../i18n';
import I18n from '../i18n';
import styles from '../styles/Common.module.css';
import { level3ApiEndpoint } from '../utils/apiEndpointUrl';
import { requestToBackend } from '../utils/rpcLogics';
import { User } from 'firebase/auth';
import { EmailList, Organization } from '../types';
import { XCircleIcon } from '@heroicons/react/24/outline';

export default function EmailListUpdateModal({
  currentUser,
  currentOrganization,
  openFileUploadModal,
  setOpenFileUploadModal,
  emailList,
}: {
  currentUser: User;
  currentOrganization: Organization;
  openFileUploadModal: boolean;
  setOpenFileUploadModal: Function;
  emailList: EmailList;
}) {
  const [open, setOpen] = useState(false);
  type Form = {
    file: File | null;
    name: string;
    encode: 'utf8' | 'shiftjis';
  };
  type FormError = {
    file: string;
    name: string;
    encode: string;
  };
  const [form, setForm] = useState<Form>({
    file: null,
    name: emailList.name,
    encode: 'shiftjis',
  });
  const changeVisibility = (open: boolean) => {
    setOpenFileUploadModal(open);
    setOpen(open);
    setHttpResponseError('');
  };
  useEffect(() => {
    setOpen(openFileUploadModal);
  });
  useEffect(() => {
    setForm({ file: null, name: emailList.name, encode: 'shiftjis' });
  }, [open]);
  const [httpResponseError, setHttpResponseError] = useState('');
  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files != null && files[0]) {
      const file = files[0];
      if (file.type !== 'text/csv') {
        return;
      }
      setForm((form) => ({
        ...form,
        file: files[0],
      }));
    }
  };

  const [openFormError, setOpenFormError] = useState(false);
  const [formError, setFormError] = useState<FormError>({
    file: '',
    name: '',
    encode: '',
  });

  const isValidForm = (form: Form): boolean => {
    const result = { file: '', name: '', encode: '' };
    if (!form.file) {
      result.file = 'ファイルは必須です';
    }
    if (form.file && form.file.type !== 'text/csv') {
      result.file = 'ファイルが不適切です';
    }
    if (form.file && form.file.size > 50 * 1024 * 1024) {
      result.file =
        'ファイルサイズが大きすぎます。50MB未満のファイルを選択してください。';
    }
    if (form.name.length > 100) {
      result.name =
        'メールアドレスリストの名前は100文字以内で入力してください。';
    }

    if (Object.values(result).some((v) => v != '')) {
      setOpenFormError(true);
      setFormError(result);
      return false;
    } else {
      return true;
    }
  };
  const [isUploading, setIsUploading] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!emailList) {
      return;
    }
    e.preventDefault();
    setIsUploading(true);
    const isValid = isValidForm(form);
    if (!isValid) {
      setIsUploading(false);
      return;
    }
    const formData = new FormData();
    formData.append('name', form.name);
    formData.append('encode', form.encode);
    if (form.file) {
      formData.set('file', form.file);
    }

    if (!currentUser || !currentOrganization) {
      setIsUploading(false);
      return;
    }
    (async () => {
      const [_, error] = await requestToBackend(
        currentUser,
        `${level3ApiEndpoint()}/mail-list/${emailList.id}?organizationId=${
          currentOrganization.id
        }`,
        'PATCH',
        formData
      );

      if (!error) {
        setFormError({ file: '', name: '', encode: '' });
        setOpenFormError(false);
        setForm({ file: null, name: '', encode: 'shiftjis' });
        changeVisibility(false);
      } else {
        setHttpResponseError(error);
      }
      setIsUploading(false);
    })();
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center mb-5">
                    <DialogTitle
                      as="h2"
                      className="text-xl font-medium leading-6 text-gray-900"
                    >
                      メールリスト更新
                    </DialogTitle>
                  </div>
                </div>
                <div
                  className="rounded-md bg-red-50 p-4 my-5"
                  hidden={!openFormError}
                >
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-red-400"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        入力された内容が正しくありません。
                      </h3>
                      <div className="mt-2 text-sm text-red-700">
                        <ul role="list" className="list-disc space-y-1 pl-5">
                          {Object.values(formError)
                            .filter((v) => v != '')
                            .map((v) => {
                              return <li>{v}</li>;
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <form
                    className="space-y-8 divide-y divide-gray-200"
                    onSubmit={handleSubmit}
                    encType={'multipart/form-data'}
                    method={'POST'}
                  >
                    <div>
                      <div>
                        <div>
                          <div>
                            <div className="space-y-12">
                              <div className="sm:col-span-4">
                                <label
                                  htmlFor="name"
                                  className="block leading-6 text-gray-900"
                                >
                                  <span className="font-medium text-lg">
                                    メールリスト名
                                  </span>
                                  <br />
                                </label>
                                <div className="mt-2">
                                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600d">
                                    <input
                                      id="name"
                                      name="name"
                                      type="text"
                                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                      value={form.name}
                                      onChange={(e) => {
                                        setForm((prevForm) => ({
                                          ...prevForm,
                                          name: e.target.value,
                                        }));
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="sm:col-span-4">
                                <label
                                  htmlFor="name"
                                  className="block leading-6 text-gray-900"
                                >
                                  <p className="font-medium text-lg">
                                    文字コード
                                  </p>
                                </label>
                                <div className="mt-2">
                                  <fieldset>
                                    <div className="space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                      {['shiftjis', 'utf8'].map((encode) => (
                                        <div
                                          key={encode}
                                          className="flex items-center"
                                        >
                                          <input
                                            defaultChecked={
                                              encode === 'shiftjis'
                                            }
                                            name="notification-method"
                                            type="radio"
                                            onChange={() => {
                                              setForm({
                                                ...form,
                                                encode: encode as
                                                  | 'shiftjis'
                                                  | 'utf8',
                                              });
                                            }}
                                            className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                                          />
                                          <label className="ml-3 block text-sm/6 font-medium text-gray-900">
                                            {encode}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </fieldset>
                                </div>
                              </div>
                              <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                <div className="space-y-1 text-center">
                                  <div className="flex text-sm text-gray-600">
                                    <label
                                      htmlFor="file"
                                      className="relative cursor-pointer rounded-md bg-white font-medium text-gray-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-gray-500 focus-within:ring-offset-2 hover:text-gray-500"
                                    >
                                      <svg
                                        className="mx-auto h-12 w-12 text-gray-400"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 48 48"
                                        aria-hidden="true"
                                      >
                                        <path
                                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      <span>{i18n.t('action.select')}</span>
                                      <input
                                        id="file"
                                        name="file"
                                        type="file"
                                        className="sr-only"
                                        onChange={onChangeFile}
                                        accept=".csv"
                                      />
                                      <p className="text-xs text-gray-500">
                                        対応ファイル形式: CSV
                                      </p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {httpResponseError && (
                                <p className="text-red-500 text-xs">
                                  {I18n.t(`error.${httpResponseError}`)}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {form.file && (
                      <div>
                        <p className="text-gray-500">登録されたリスト</p>
                        <p className="text-gray-500">・{form.file.name}</p>
                      </div>
                    )}

                    <div className="pt-5 flex justify-end space-x-3">
                      <button
                        type="button"
                        className={`${styles.undoButton}`}
                        onClick={() => {
                          setFormError({ file: '', name: '', encode: '' });
                          setOpenFormError(false);
                          setForm({ file: null, name: '', encode: 'shiftjis' });
                          changeVisibility(false);
                        }}
                      >
                        {i18n.t('action.cancel')}
                      </button>
                      <button
                        type="submit"
                        className={styles.button}
                        disabled={isUploading}
                      >
                        {i18n.t('action.upload')}
                      </button>
                    </div>
                  </form>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
