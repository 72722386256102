import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/20/solid';
import { useState } from 'react';

export default function PageNavigation({
  setPage,
  numPages,
}: {
  numPages: number;
  setPage: Function;
}) {
  const [currentPage, setCurrentPage] = useState(1);

  function onClickPageHandler(num: number) {
    if (0 < num && num <= numPages) {
      setCurrentPage(num);
      setPage(num);
    }
  }

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <a
          href="#"
          className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          onClick={() => {
            onClickPageHandler(currentPage - 1);
          }}
        >
          <ArrowLongLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Prev
        </a>
      </div>
      {10 < numPages && (currentPage <= 3 || numPages - 2 <= currentPage) && (
        <div className="hidden md:-mt-px md:flex">
          {[1, 2, 3, 4, 5].map((v, _) => (
            <a
              href="#"
              className={
                v === currentPage
                  ? 'inline-flex items-center border-t-2 border-gray-500 px-4 pt-4 text-sm font-medium text-gray-600'
                  : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'
              }
              aria-current="page"
              onClick={() => {
                onClickPageHandler(v);
              }}
            >
              {v}
            </a>
          ))}
          <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
            ...
          </span>
          {[
            numPages - 4,
            numPages - 3,
            numPages - 2,
            numPages - 1,
            numPages,
          ].map((v, i_) => (
            <a
              href="#"
              className={
                v === currentPage
                  ? 'inline-flex items-center border-t-2 border-gray-500 px-4 pt-4 text-sm font-medium text-gray-600'
                  : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'
              }
              aria-current="page"
              onClick={() => {
                onClickPageHandler(v);
              }}
            >
              {v}
            </a>
          ))}
        </div>
      )}

      {10 < numPages && 3 < currentPage && currentPage < numPages - 2 && (
        <div className="hidden md:-mt-px md:flex">
          {[1, 2].map((v, _) => (
            <a
              href="#"
              className="'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'"
              aria-current="page"
              onClick={() => {
                onClickPageHandler(v);
              }}
            >
              {v}
            </a>
          ))}
          <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
            ...
          </span>

          {[
            currentPage - 2,
            currentPage - 1,
            currentPage,
            currentPage + 1,
            currentPage + 2,
          ].map((v, _) => (
            <a
              href="#"
              className={
                v === currentPage
                  ? 'inline-flex items-center border-t-2 border-gray-500 px-4 pt-4 text-sm font-medium text-gray-600'
                  : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'
              }
              aria-current="page"
              onClick={() => {
                onClickPageHandler(v);
              }}
            >
              {v}
            </a>
          ))}

          <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
            ...
          </span>

          {[numPages - 1, numPages].map((v, _) => (
            <a
              href="#"
              className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              aria-current="page"
              onClick={() => {
                onClickPageHandler(v);
              }}
            >
              {v}
            </a>
          ))}
        </div>
      )}

      {numPages <= 10 && (
        <div className="hidden md:-mt-px md:flex">
          {[...Array(numPages)].map((_, i) => (
            <a
              href="#"
              className={
                i + 1 === currentPage
                  ? 'inline-flex items-center border-t-2 border-gray-500 px-4 pt-4 text-sm font-medium text-gray-600'
                  : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'
              }
              aria-current="page"
              onClick={() => {
                onClickPageHandler(i + 1);
              }}
            >
              {i + 1}
            </a>
          ))}
        </div>
      )}
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <a
          href="#"
          className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          onClick={() => {
            onClickPageHandler(currentPage + 1);
          }}
        >
          Next
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </a>
      </div>
    </nav>
  );
}
