import { useEffect, lazy, useState, Suspense, ReactNode } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { ContractQuota, Organization, UserRecord } from './types';
import { firebaseAuth } from './firebaseAuth';
import { User } from 'firebase/auth';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import { level2ApiEndpoint, level3ApiEndpoint } from './utils/apiEndpointUrl';
import { requestToBackend } from './utils/rpcLogics';
import I18n from './i18n';
import { loggerError } from './utils/logger';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import MailList from './pages/MailList';
import MailListDetail from './pages/MailListDetail';
import NoOrganization from './pages/NoOrganizations';

const ResourceList = lazy(() => import('./pages/ResourceList'));
const SidebarLayout = lazy(() => import('./components/SidebarLayout'));
const Term = lazy(() => import('./pages/Term'));
const Welcome = lazy(() => import('./pages/Welcome'));
const PageNotFound = lazy(() => import('./components/PageNotFound'));
const SpecifiedCommercialTransactions = lazy(
  () => import('./pages/SpecifiedCommercialTransactions')
);
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const LP = lazy(() => import('./pages/LP'));
const AccessLogExplorer = lazy(() => import('./pages/AccessLogExplorer'));
const ResourceDetail = lazy(() => import('./pages/ResourceDetail'));
const OrganizationSetting = lazy(() => import('./pages/OrganizationSetting'));
const UserSetting = lazy(() => import('./pages/UserSetting'));
const ResourceAccessControlList = lazy(
  () => import('./pages/ResourceAccessControlList')
);
const SharingResource = lazy(() => import('./pages/SharingResource'));
const NoAuthLayout = lazy(() => import('./components/NoAuthLayout'));
const Receive = lazy(() => import('./pages/Receive'));
interface DelayedFallbackProps {
  delay: number;
  children: ReactNode;
}

const DelayedFallback: React.FC<DelayedFallbackProps> = ({
  delay,
  children,
}) => {
  const [showFallback, setShowFallback] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFallback(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return showFallback ? <>{children}</> : null;
};

function App() {
  const ignoreAuthPath = [
    '/',
    '/company',
    '/privacy-policy',
    '/specified-commercial-transactions',
    '/receive',
    '/term',
    '/signin',
    '/signup',
  ];

  const currentUrl = new URL(window.location.href);
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [currentOrganization, setCurrentOrganization] = useState<
    Organization | undefined
  >(undefined);
  const [organizationOptions, setOrganizationOptions] = useState<
    Organization[]
  >([]);
  const [isLoading, setLoading] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  if (!ignoreAuthPath.includes(window.location.pathname)) {
    firebaseAuth.onAuthStateChanged(function (user) {
      if (user) {
        setCurrentUser(user);
      }
    });
  }
  const [contractQuota, setContractQuota] = useState<ContractQuota>();

  setTimeout(() => {
    if (!isPageLoaded) {
      setLoading(false);
      setIsPageLoaded(true);
    }
  }, 1000);

  useEffect(() => {
    if (ignoreAuthPath.includes(window.location.pathname)) {
      return;
    }
    if (!currentUser || isLoading) {
      return;
    }

    (async () => {
      setLoading(true);
      const [response, error1] = await requestToBackend(
        currentUser,
        `${level2ApiEndpoint()}/user`,
        'GET'
      );
      if (error1) {
        loggerError(
          I18n.t(`error.${error1}`),
          new Error('failed to get user info')
        );
        return;
      }
      if (!response) {
        return;
      }
      const user: UserRecord = await response.json();
      if (!user) {
        return;
      }

      const [response2, error2] = await requestToBackend(
        currentUser,
        `${level2ApiEndpoint()}/organizations`,
        'GET'
      );
      if (error2) {
        loggerError(
          I18n.t(`error.${error2}`),
          new Error('failed to get organizations')
        );
        setIsPageLoaded(true);
        return;
      }

      if (!response2) {
        return;
      }
      const organizations: Organization[] = await response2.json();

      if (!organizations) {
        setOrganizationOptions([]);
        setIsPageLoaded(true);
        return;
      }
      setOrganizationOptions(organizations);
      const currentOrganizationId =
        currentUrl.searchParams?.get('organizationId');
      const currentOrganization = organizations.filter(
        (t) => t.id === currentOrganizationId
      )[0];

      if (currentOrganization) {
        setCurrentOrganization(currentOrganization);
      } else {
        currentUrl.searchParams.set('organizationId', organizations[0].id);
        window.location.href = currentUrl.href;
        setCurrentOrganization(organizations[0]);
      }

      if (currentUser && currentOrganization) {
        const [response3, error3] = await requestToBackend(
          currentUser,
          `${level3ApiEndpoint()}/contract-quota?organizationId=${
            currentOrganization.id
          }`,
          'GET'
        );
        if (error3) {
          loggerError(
            I18n.t(`error.${error3}`),
            new Error('failed to get contract quota')
          );
          setIsPageLoaded(true);
          return;
        }

        const contractQuota: ContractQuota = await response3.json();
        if (!contractQuota) {
          setIsPageLoaded(true);
          return;
        }
        setContractQuota(contractQuota);
      }

      setIsPageLoaded(true);
    })();
  }, [currentUser]);

  if (!isPageLoaded) {
    return <div></div>;
  } else if (currentUser && currentOrganization && contractQuota) {
    return (
      <HelmetProvider>
        <BrowserRouter>
          <Suspense
            fallback={
              <DelayedFallback delay={2000}>
                <div>Loading...</div>
              </DelayedFallback>
            }
          >
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            <Routes>
              <Route element={<NoAuthLayout />}>
                <Route path="" element={<LP />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/term" element={<Term />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route
                  path="/specified-commercial-transactions"
                  element={<SpecifiedCommercialTransactions />}
                />
              </Route>
              <Route
                element={
                  <SidebarLayout
                    organizationOptions={organizationOptions}
                    currentOrganization={currentOrganization}
                    currentUser={currentUser}
                  />
                }
              >
                {/* <Route path="/" element={<DocumentList currentUser={currentUser} />} /> */}
                <Route path="/receive" element={<Receive />} />{' '}
                <Route
                  path="/welcome"
                  element={
                    <Welcome
                      currentOrganization={currentOrganization}
                      currentUser={currentUser}
                    />
                  }
                />
                <Route
                  path="/tracking"
                  element={
                    <ResourceAccessControlList
                      currentOrganization={currentOrganization}
                      currentUser={currentUser}
                    />
                  }
                />
                <Route
                  path="/tracking/:resourceId/resourceAccessControls/:resourceAccessControlId"
                  element={
                    <SharingResource
                      currentOrganization={currentOrganization}
                      currentUser={currentUser}
                      contractQuota={contractQuota}
                    />
                  }
                />
                <Route
                  path="/access-log-explorer"
                  element={
                    <AccessLogExplorer
                      currentUser={currentUser}
                      currentOrganization={currentOrganization}
                      contractQuota={contractQuota}
                    />
                  }
                />
                <Route
                  path="/mail-list"
                  element={
                    <MailList
                      currentUser={currentUser}
                      currentOrganization={currentOrganization}
                    />
                  }
                />
                <Route
                  path="/mail-list/:id"
                  element={
                    <MailListDetail
                      currentUser={currentUser}
                      currentOrganization={currentOrganization}
                    />
                  }
                />
                <Route
                  path="/resources"
                  element={
                    <ResourceList
                      currentUser={currentUser}
                      currentOrganization={currentOrganization}
                      contractQuota={contractQuota}
                    />
                  }
                />
                <Route
                  path="/resources/:resourceId"
                  element={
                    <ResourceDetail
                      currentUser={currentUser}
                      currentOrganization={currentOrganization}
                      contractQuota={contractQuota}
                    />
                  }
                />
                <Route
                  path="/user-setting"
                  element={<UserSetting currentUser={currentUser} />}
                />
                <Route
                  path="/organization-setting"
                  element={
                    <OrganizationSetting
                      currentUser={currentUser}
                      currentOrganization={currentOrganization}
                      contractQuota={contractQuota}
                    />
                  }
                />
              </Route>

              <Route
                path="*"
                element={
                  <Navigate
                    replace
                    to={`/resources?organizationId=${currentOrganization.id}`}
                  />
                }
              />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    );
  } else if (currentUser && organizationOptions.length === 0) {
    return (
      <HelmetProvider>
        <BrowserRouter>
          <Suspense
            fallback={
              <DelayedFallback delay={2000}>
                <div>Loading...</div>
              </DelayedFallback>
            }
          >
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            <Routes>
              <Route element={<NoAuthLayout />}>
                <Route path="/receive" element={<Receive />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/term" element={<Term />} />
                <Route
                  path="/specified-commercial-transactions"
                  element={<SpecifiedCommercialTransactions />}
                />
                <Route path="" element={<LP />} />
                <Route
                  path="*"
                  element={<NoOrganization currentUser={currentUser} />}
                />
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    );
  } else {
    return (
      <HelmetProvider>
        <BrowserRouter>
          <Suspense
            fallback={
              <DelayedFallback delay={2000}>
                <div>Loading...</div>
              </DelayedFallback>
            }
          >
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            <Routes>
              <Route element={<NoAuthLayout />}>
                <Route path="/receive" element={<Receive />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/term" element={<Term />} />
                <Route
                  path="/specified-commercial-transactions"
                  element={<SpecifiedCommercialTransactions />}
                />
                <Route path="" element={<LP />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    );
  }
}

export default App;
