import { useState, useEffect } from 'react';
import i18n from '../i18n';
import Notification from '../components/Notification';
import { parseDate } from '../utils/date';
import { Organization, EmailList } from '../types';
import styles from '../styles/Common.module.css';
import { level3ApiEndpoint } from '../utils/apiEndpointUrl';
import { User } from 'firebase/auth';
import PageNavigation from '../components/pageNavigation';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import EmailListUploadModal from '../components/EmailListUploadModal';
import { ButtonBase } from '@mui/material';
import { Link } from 'react-router-dom';

export default function MailList({
  currentUser,
  currentOrganization,
}: {
  currentUser: User;
  currentOrganization: Organization;
}) {
  const [emailList, setEmailList] = useState<EmailList[] | []>([]);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [openResourceShareModal, setOpenResourceShareModal] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const dataPerPage = 10;

  const fetchEmailList = () => {
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(
          `${level3ApiEndpoint()}/mail-list?organizationId=${
            currentOrganization.id
          }&onlyMeta=true`,
          {
            headers: { Authorization: idToken },
          }
        )
          .then(async (response) => await response.json())
          .then((emailList: EmailList[]) => {
            setOpenNotification(false);
            if (emailList) {
              setEmailList(emailList);
            }
          })
          .catch((e) => {
            throw new Error(
              '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
            );
          });
      })
      .catch(function (error) {
        // Handle error
      });
  };

  useEffect(() => {
    fetchEmailList();
  }, [openFileUploadModal, openResourceShareModal]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Cloud Linker - 書類管理</title>
        <meta
          name="description"
          content="書類の一元管理を行うページです。検索機能や分類オプションを活用し、必要な書類を効率的に整理・管理することが可能です。"
        />
      </Helmet>

      <div className="p-4 sm:p-6 lg:p-8 content-center w-full">
        <Notification
          infoMessage={infoMessage}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
        <EmailListUploadModal
          currentUser={currentUser}
          currentOrganization={currentOrganization}
          openFileUploadModal={openFileUploadModal}
          setOpenFileUploadModal={setOpenFileUploadModal}
        />

        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              資料送付先メールアドレスリスト管理
            </h1>
            <div className="overflow-hidden rounded-lg bg-white shadow mt-3">
              <div className="px-2 py-3 sm:p-3">
                <p className=" mt-1">CSVフォーマット</p>
                <p>
                  <button className="rounded bg-blue-50 px-2 py-1 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-100 mt-3">
                    <a href="/files/CSV-Sample-Shift_JIS.csv" download={true}>
                      テンプレートダウンロード（Shift_JIS、主にWindows OS用）
                    </a>
                  </button>
                </p>
                <p>
                  <button className="rounded bg-blue-50 px-2 py-1 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-100 mt-3">
                    <a href="/files/CSV-Sample-UTF_8.csv" download={true}>
                      テンプレートダウンロード（UTF-8、主にMac OS用）
                    </a>
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className={styles.button}
              onClick={() => {
                setOpenFileUploadModal(true);
              }}
            >
              {i18n.t('action.add')}
            </button>
          </div>
        </div>

        <div className="mt-10 flow-root">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        メールリスト名
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {i18n.t('noun.created_at')}
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {emailList.length > 0 &&
                      emailList
                        .slice(
                          pageNumber * dataPerPage - dataPerPage,
                          pageNumber * dataPerPage
                        )
                        .map((row) => (
                          <tr key={row.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <ButtonBase
                                to={
                                  '/mail-list/' +
                                  row.id +
                                  '?organizationId=' +
                                  currentOrganization.id
                                }
                                component={Link}
                              >
                                {row.name}
                              </ButtonBase>
                            </td>

                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {parseDate(row.createdAt)}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="my-10">
            <PageNavigation
              numPages={Math.ceil(emailList.length / dataPerPage)}
              setPage={setPageNumber}
            />
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}
