import { useState, useEffect } from 'react';
import i18n from '../i18n';
import Notification from '../components/Notification';
import { parseDate } from '../utils/date';
import { Organization, EmailList } from '../types';
import styles from '../styles/Common.module.css';
import { level3ApiEndpoint } from '../utils/apiEndpointUrl';
import { User } from 'firebase/auth';
import PageNavigation from '../components/pageNavigation';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import EmailListUpdateModal from '../components/EmailListUpdateModal';
import { useParams } from 'react-router-dom';
import { loggerError } from '../utils/logger';

export default function MailList({
  currentUser,
  currentOrganization,
}: {
  currentUser: User;
  currentOrganization: Organization;
}) {
  const [emailList, setEmailList] = useState<EmailList>();
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [openResourceShareModal, setOpenResourceShareModal] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const dataPerPage = 10;
  const { id } = useParams();

  const deleteMailList = (emailList: EmailList) => {
    var confirmMessage = i18n.t('confirmation.are_you_sure_to_delete');

    if (!confirm(confirmMessage)) {
      return;
    }

    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(
          `${level3ApiEndpoint()}/mail-list/${emailList.id}?organizationId=${
            currentOrganization.id
          }`,
          {
            headers: { Authorization: idToken },
            method: 'DELETE',
          }
        )
          .then((response) => {
            if (response.status === 200) {
              window.location.href =
                '/mail-list?organizationId=' + currentOrganization.id;
            }
          })
          .catch((e) => {
            loggerError('failed to delete mail list', e);
          });
      })
      .catch((e) => {
        loggerError('failed to delete mail liste', e);
      });
  };

  const fetchEmailList = () => {
    if (!id) {
      return;
    }
    currentUser
      .getIdToken(true)
      .then((idToken) => {
        fetch(
          `${level3ApiEndpoint()}/mail-list/${id}?organizationId=${
            currentOrganization.id
          }`,
          {
            headers: { Authorization: idToken },
          }
        )
          .then(async (response) => await response.json())
          .then((emailList: EmailList) => {
            setOpenNotification(false);
            if (emailList) {
              setEmailList(emailList);
            }
          })
          .catch((e) => {
            throw new Error(
              '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
            );
          });
      })
      .catch(function (error) {
        // Handle error
      });
  };

  useEffect(() => {
    fetchEmailList();
  }, [openFileUploadModal, openResourceShareModal]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Cloud Linker - 書類管理</title>
        <meta
          name="description"
          content="書類の一元管理を行うページです。検索機能や分類オプションを活用し、必要な書類を効率的に整理・管理することが可能です。"
        />
      </Helmet>

      <div className="p-4 sm:p-6 lg:p-8 content-center w-full">
        <Notification
          infoMessage={infoMessage}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
        {emailList && (
          <EmailListUpdateModal
            currentUser={currentUser}
            currentOrganization={currentOrganization}
            openFileUploadModal={openFileUploadModal}
            setOpenFileUploadModal={setOpenFileUploadModal}
            emailList={emailList}
          />
        )}

        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              {emailList?.name}（作成日：
              {emailList ? parseDate(emailList.createdAt) : ''}）
            </h1>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className={styles.button}
              onClick={() => {
                setOpenFileUploadModal(true);
              }}
            >
              リストを更新する
            </button>
          </div>
        </div>
        {emailList && emailList.emails && (
          <div className="mt-10 flow-root">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 px-3 "
                        >
                          会社名
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 px-3 "
                        >
                          名前
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 px-3"
                        >
                          メールアドレス
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 bg-white">
                      {emailList.emails.length > 0 &&
                        emailList.emails
                          .slice(
                            pageNumber * dataPerPage - dataPerPage,
                            pageNumber * dataPerPage
                          )
                          .map((row) => (
                            <tr key={row.email}>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {row.companyName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {row.name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {row.email}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="my-10">
              <PageNavigation
                numPages={Math.ceil(emailList.emails.length / dataPerPage)}
                setPage={setPageNumber}
              />
            </div>
          </div>
        )}
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mt-10">
          <div className="px-4 py-5 sm:px-6 bg-gray-200">危険な操作</div>
          <div className="px-4 py-5 sm:p-6">
            <p>
              <button
                onClick={() => {
                  if (emailList) {
                    deleteMailList(emailList);
                  }
                }}
                className={styles.buttonDanger}
              >
                リストを削除する
              </button>
            </p>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}
