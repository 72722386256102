import { useState } from 'react';
import I18n from '../i18n';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { level1ApiEndpoint } from '../utils/apiEndpointUrl';
import { loggerError } from '../utils/logger';

import MfaForm from '../components/MfaForm';

export default function SignIn() {
  const [httpResponseResult, setHttpResponseResult] = useState({
    success: '',
    error: '',
  });
  const auth = getAuth();
  auth.languageCode = 'ja';

  const continueWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (!credential) {
          return;
        }
        const token = credential.accessToken;
        if (!token) {
          return;
        }
        const formData = new FormData();
        const user = result.user;
        if (!user.email) {
          return;
        }
        formData.append('email', user.email);
        user
          .getIdToken(true)
          .then((token) => {
            fetch(`${level1ApiEndpoint()}/create-session`, {
              headers: { Authorization: token },
              method: 'POST',
              body: formData,
              credentials: 'include',
            })
              .then((response) => {
                if (response.ok) {
                  const message = structuredClone(httpResponseResult);
                  message.success = I18n.t(
                    'individual_pages.sign_up.successfully_created_user'
                  );
                  setHttpResponseResult(message);
                  window.location.replace('/welcome');
                } else {
                  throw new Error(
                    '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
                  );
                }
              })
              .catch(() => {
                throw new Error(
                  '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
                );
              });
          })
          .catch((e: Error) => {
            setFormError(e.message);
            loggerError('failed to signin', e);
          });
      })
      .catch((err) => {
        setFormError(err.message);
        loggerError('failed to signin', err);
      });
  };
  const [formError, setFormError] = useState('');

  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 p-4">
      <div className="p-4 max-w-[80%] m-auto ">
        <img
          alt="Your Company"
          src="/images/signin.png"
          className="mx-auto max-w-full rounded"
        />
      </div>
      <div className="p-4">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
              ログイン
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <MfaForm type="signin" />
            <p className="mt-10 text-center text-sm/6 text-gray-500">
              <a
                href="/signup"
                className="font-semibold text-blue-600 hover:text-blue-500"
              >
                新規登録はこちら
              </a>
            </p>
            <hr className="my-5" />
            <div>
              <p className="m-auto">
                <button className="w-48 my-5" onClick={continueWithGoogle}>
                  <img src="/images/web_light_rd_ctn@4x.png" loading="lazy" />
                </button>
              </p>
              <p className="text-xs text-gray-400">
                ログインにより
                <a href="/term" className="hover:underline" target="_blank">
                  {I18n.t('noun.terms')}
                </a>
                、
                <a
                  href="/privacy-policy"
                  className="hover:underline"
                  target="_blank"
                >
                  {I18n.t('noun.privacy_policy')}
                </a>
                と
                <a
                  href="https://support.sigq.jp/hc/ja/articles/39357914025753--%E3%83%99%E3%83%BC%E3%82%BF%E7%89%88%E5%88%A9%E7%94%A8%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E9%87%8D%E8%A6%81%E3%81%AA%E3%81%8A%E7%9F%A5%E3%82%89%E3%81%9B"
                  className="hover:underline"
                  target="blank"
                >
                  ベータ版注意・免責事項
                </a>
                に同意したものとみなします。また、必要に応じて、メールで広告、プロモーション、その他の通知を送信することがあります。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
