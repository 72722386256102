import { useState } from 'react';
import { firebaseAuth } from '../firebaseAuth';
import { Button } from '@mui/material';
import { loggerError } from '../utils/logger';
import { User } from 'firebase/auth';
import { level2ApiEndpoint } from '../utils/apiEndpointUrl';

export default function NoOrganization({ currentUser }: { currentUser: User }) {
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const createOrg = async () => {
    if (!currentUser || !currentUser.email) {
      return;
    }
    const formData = new FormData();
    formData.append('email', currentUser.email);
    currentUser
      .getIdToken(true)
      .then((token) => {
        fetch(`${level2ApiEndpoint()}/organizations`, {
          headers: { Authorization: token },
          method: 'POST',
          body: formData,
          credentials: 'include',
        })
          .then(async (response) => {
            if (response.ok) {
              window.location.replace('/welcome');
            } else {
              throw new Error(
                '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
              );
            }
          })
          .catch((e) => {
            return e;
          });
      })
      .catch((e: Error) => {
        loggerError('failed to create session', e);
      });
  };
  setTimeout(() => {
    setShouldDisplay(true);
  }, 3000);
  if (shouldDisplay) {
    return (
      <>
        <main className="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
          <div className="text-center">
            <p>現在所属している組織がありません</p>
            <p>
              <Button
                onClick={() => {
                  firebaseAuth.signOut().then((r) => {
                    location.reload();
                    window.location.href = '/';
                  });
                }}
              >
                サインアウトする
              </Button>
            </p>
            <p>
              <Button
                onClick={async () => {
                  await createOrg();
                }}
              >
                新しい組織を作成する
              </Button>
            </p>
          </div>
        </main>
      </>
    );
  } else {
    return <></>;
  }
}
